<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/medicine_platform_bg.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title mb20">供应链平台</p>
            <p class="font-txt">
              多仓调度 <span class="font-txt-tag">|</span> 运力编排 <span class="font-txt-tag">|</span> AI算法
            </p>
            <!-- <div class="next-btn">进一步了解</div> -->
          </div>
          <!-- <div class="bg-text-right">
            <p class="font-one">Technology leads transform, Data drives serviceValue</p>
            <p class="font-two">Transparent Word</p>
          </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h100"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" height="75px" ref="tabIndex"></Tab>
      </div>
      <!-- 药链云-SAAS药企供应链平台-->
      <div class="yly-box" v-show="currentIndex == 0">
        <Title titleZh="产品介绍" titleEn=""></Title>
        <!-- 介绍-->
        <div class="main-contain">
          <div class="contain-intro-box">
            <img src="../../assets/images/medicine_platform_cpjs1.png" alt="" />
            <div class="contain-intro-txt">
              <div class="contain-intro-title">药链云-SAAS药企供应链平台</div>
              <p>药链云平台支持医药企业的供应链全环节管理，是药企的供应链控制塔；</p>
              <p>
                传世药链云整合了订单管理(OMS)、三方物流(TPL)、配送管理(TMS)、多仓调度(MWD)核心模块，帮助药企低投入实现三方业务开展、全国仓网与车网的天网建设。
              </p>
              <p>
                传世药链云还集成了开放式接口平台，已与市场主流ERP、WMS系统接口。
              </p>
              <img class="w100f" src="../../assets/images/medicine_platform_cpjs2.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 订单调度(OMS) -->
        <div class="main-contain">
          <Title
            titleZh="订单调度(OMS)"
            titleEn="订单调度模块主要面向需要解决复杂订单需求的企业"
            colorZh="#151515"
            fontSizeEn="16px"
            colorEn="#6A6A6A"
            marginBottomZh="76px"
          ></Title>
          <img class="w90f mt20" src="../../assets/images/medicine_platform_oms.png" alt="" />
        </div>
        <div class="h100"></div>
        <!-- 三方物流(TPL) 1622-->
        <div class="tpl-box">
          <div class="main-contain">
            <Title
              titleZh="三方物流(TPL)"
              titleEn="三方物流模块主要面向积极开展三方物流业务的企业"
              colorZh="#151515"
              fontSizeEn="16px"
              colorEn="#6A6A6A"
              marginTopZh="0"
              marginBottomZh="76px"
            ></Title>
            <div class="tpl-content-box">
              <div class="tpl-list">
                <img src="../../assets/images/medicine_platform_sfwl1.png" alt="" />
                <div class="tpl-txt-box">
                  <div class="tpl-title">提供合规类数据报表</div>
                  <p>质量类、三方业务类、库存类等，协助企业合规通过医药三方备案；</p>
                </div>
              </div>
              <div class="tpl-list">
                <img src="../../assets/images/medicine_platform_sfwl2.png" alt="" />
                <div class="tpl-txt-box">
                  <div class="tpl-title">上游接通多类货主(工业、商业、B2B、B2C)的委托订单</div>
                  <p>
                    向货主提供全链路数据(订单执行数据、仓内作业数据、配送数据、冷链数据、含电子回单的回签数据等)、质量数据、库存数据、物流报价、费用核算等；
                  </p>
                </div>
              </div>
              <div class="tpl-list">
                <img src="../../assets/images/medicine_platform_sfwl3.png" alt="" />
                <div class="tpl-txt-box">
                  <div class="tpl-title">下游管理外租物流供应商(委外仓储、委外配送)</div>
                  <p>管理内容包括合同、仓网与车网调度、服务质量监控、费用结算等；</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 仓网调度 -->
        <div class="main-contain">
          <Title
            titleZh="仓网调度"
            titleEn="仓网调度模块主要面向有全国仓网布局需求的企业"
            colorZh="#151515"
            fontSizeEn="16px"
            colorEn="#6A6A6A"
            marginBottomZh="76px"
          ></Title>
          <img src="../../assets/images/medicine_platform_cwdd.png" alt="" />
          <div class="h150"></div>
        </div>
        <!-- 车网调度 -->
        <div class="tpl-box">
          <div class="main-contain">
            <Title
              titleZh="车网调度"
              titleEn="车网调度模块主要面向有建设全国运力网络需求的企业"
              colorZh="#151515"
              fontSizeEn="16px"
              colorEn="#6A6A6A"
              marginBottomZh="76px"
              marginTopZh="0"
            ></Title>
            <div class="cw-box">
              <div class="cw-list">
                <img src="../../assets/images/medicine_platform_cwdd1.png" alt="" />
                <p>支持干线-支线-城配的多级网络架构；</p>
              </div>
              <div class="cw-list">
                <img src="../../assets/images/medicine_platform_cwdd2.png" alt="" />
                <p>支持自营运力+联盟运力的混合型车网形态；</p>
              </div>
              <div class="cw-list">
                <img src="../../assets/images/medicine_platform_cwdd3.png" alt="" />
                <p>支持陆运、航运、陆运+航运的单一或多式联运调度；</p>
              </div>
            </div>
            <div class="cw-box">
              <div class="cw-list">
                <img src="../../assets/images/medicine_platform_cwdd4.png" alt="" />
                <p>提供物流报价、航班管理、线路管理等功能；</p>
              </div>
              <div class="cw-list">
                <img src="../../assets/images/medicine_platform_cwdd5.png" alt="" />
                <p>支持运营车辆与司机的准入审核管理；</p>
              </div>
              <div class="cw-list">
                <img src="../../assets/images/medicine_platform_cwdd6.png" alt="" />
                <p>提供标准接口方案，已实现与多家主流物流公司的系统对接；</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 运输管理(TMS) -->
        <div class="tms-box">
          <div class="main-contain">
            <Title
              titleZh="运输管理(TMS)"
              titleEn="运输管理模块主要是面向需要有城配管理需求的企业"
              colorZh="#151515"
              fontSizeEn="16px"
              colorEn="#6A6A6A"
              marginBottomZh="76px"
            ></Title>
          </div>
          <div class="tms-content-box">
            <img src="../../assets/images/medicine_platform_tms.png" alt="" />
            <div class="tms-bottom-box">
              <div class="tms-list">支持自有车辆、紧密型合作承运商的多种形式车辆调度；</div>
              <div class="tms-list">提供智能排车排线算法，为企业节省车队管理成本；</div>
              <div class="tms-list">
                支持集单排车、一单一车/多车、分类排车(常温、冷链、精神品、危险品) 、灵活插单、并线并车、回货重排等；
              </div>
              <div class="tms-list">支持线路规划、手机签收、电子回单、司机考核等。</div>
            </div>
          </div>
        </div>
        <!-- 财务管理 -->
        <div class="main-contain">
          <Title
            titleZh="财务管理"
            titleEn="财务管理模块主要面向有复杂物流费用核算管理需求的企业"
            colorZh="#151515"
            fontSizeEn="16px"
            colorEn="#6A6A6A"
            marginBottomZh="76px"
          ></Title>
          <div class="cwgl-box">
            <div class="cwgl-list">
              <img src="../../assets/images/medicine_platform_cwgl1.png" alt="" />
              <div class="cwgl-title">核算方式</div>
              <div class="cwgl-line"></div>
              <p>支持按件、按重量、按体积等多种物流价格核算方式。</p>
            </div>
            <div class="cwgl-list">
              <img src="../../assets/images/medicine_platform_cwgl2.png" alt="" />
              <div class="cwgl-title">多种报价单核算</div>
              <div class="cwgl-line"></div>
              <p>支持复杂多样的物流公司报价单、支持物流毛利核算。</p>
            </div>
            <div class="cwgl-list">
              <img src="../../assets/images/medicine_platform_cwgl3.png" alt="" />
              <div class="cwgl-title">平台协同</div>
              <div class="cwgl-line"></div>
              <p>提供生成同上游的对账单、同下游的结算单功能。</p>
            </div>
            <div class="cwgl-list">
              <img src="../../assets/images/medicine_platform_cwgl4.png" alt="" />
              <div class="cwgl-title">报表支持</div>
              <div class="cwgl-line"></div>
              <p>为财务人员提供丰富的统计报表。</p>
            </div>
          </div>
        </div>
        <!-- 智能算法 -->
        <div class="znsf-box">
          <div class="main-contain">
            <Title
              titleZh="智能算法"
              titleEn="算法驱动供应链实现智能调度"
              colorZh="#151515"
              fontSizeEn="16px"
              colorEn="#6A6A6A"
              marginBottomZh="76px"
            ></Title>
          </div>
          <div class="znsf-wrap-box">
            <div class="znsf-left">
              <div class="znsf-tab">
                <div
                  :class="znsfTabIndex == index ? 'znsf-tab-list znsf-tab-list-active' : 'znsf-tab-list'"
                  v-for="(item, index) in znsfTab"
                  :key="index"
                  @click="znsfTabChange(index)"
                >
                  {{ item.title }}
                </div>
              </div>
              <div class="znsf-intro-box" v-show="znsfTabIndex == 0">
                <div class="znsf-intro-title">选仓算法</div>
                <p>
                  根据客户的需求数据(即销量数据)以及工厂到各个客户的时间要求、成本要求以及紧急程度等，以时间成本优先原则，利用重心法、P中值等选仓模型选择最佳的仓库位置，实现多方共赢。
                </p>
                <!-- <div class="znsf-btn">了解更多</div> -->
              </div>
              <div class="znsf-intro-box" v-show="znsfTabIndex == 1">
                <div class="znsf-intro-title">选品算法</div>
                <p>
                  利用仓库发货数据，根据货品的需求频率和需求稳定度两个维度来进行选品分析，选择需求频率高、需求稳定度高的短尾产品实现前置仓的周转率，减少需求频率极低、需求稳定度不高的长尾产品，降低库存成本。
                </p>
                <!-- <div class="znsf-btn">了解更多</div> -->
              </div>
              <div class="znsf-intro-box" v-show="znsfTabIndex == 2">
                <div class="znsf-intro-title">销售预测算法</div>
                <p>
                  根据以往的销售数据，通过观察数据的趋势、周期、季节性等因素，利用ARIMA模型预测未来周期的销售数据，以便于仓库的补货。
                </p>
                <!-- <div class="znsf-btn">了解更多</div> -->
              </div>
              <div class="znsf-intro-box" v-show="znsfTabIndex == 3">
                <div class="znsf-intro-title">智能排单排线排车算法</div>
                <p>
                  多达40项业务参数形成基于条件的配送路径优化(VCRP)，智能生成配送计划；算法涵盖时间窗口、路网矩阵、满载率、车型匹配、多点配送等因素；实现成本最优、里程最优、耗时最短、均衡规划。
                </p>
                <!-- <div class="znsf-btn">了解更多</div> -->
              </div>
            </div>
            <img v-show="znsfTabIndex == 0" src="../../assets/images/medicine_platform_znsf1.png" alt="" />
            <img v-show="znsfTabIndex == 1" src="../../assets/images/medicine_platform_znsf2.png" alt="" />
            <img v-show="znsfTabIndex == 2" src="../../assets/images/medicine_platform_znsf3.png" alt="" />
            <img v-show="znsfTabIndex == 3" src="../../assets/images/medicine_platform_znsf4.png" alt="" />
          </div>
        </div>
        <!-- 数据分析 -->
        <div class="tpl-box pb0">
          <div class="main-contain">
            <Title
              titleZh="数据分析"
              titleEn="强大的自研低代码BI模块，为客户提供极其方便的数据统计、分析、挖掘、导出能力，输出效果极其优美"
              colorZh="#151515"
              fontSizeEn="16px"
              colorEn="#6A6A6A"
              marginBottomZh="76px"
              marginTopZh="0"
              widthEn="432px"
            ></Title>
            <img class="w100f" src="../../assets/images/medicine_platform_sjfx.png" alt="" />
          </div>
        </div>
        <!-- 业务协同 -->
        <div class="main-contain">
          <Title
            titleZh="业务协同"
            titleEn="业务协同模块主要面向有线上协同医院采购供应业务的企业"
            colorZh="#151515"
            fontSizeEn="16px"
            colorEn="#6A6A6A"
            marginBottomZh="76px"
          ></Title>
          <div class="ywxt-box">
            <div class="ywxt-list">
              <img src="../../assets/images/medicine_platform_ywxt1.png" alt="" />
              <p>连通医院订单至企业ERP，快速响应医院的采购要求;</p>
            </div>
            <div class="ywxt-list">
              <img src="../../assets/images/medicine_platform_ywxt2.png" alt="" />
              <p>线上提供销售出库数据及发票数据，完成货票同行要求的数据提供;</p>
            </div>
            <div class="ywxt-list">
              <img src="../../assets/images/medicine_platform_ywxt3.png" alt="" />
              <p>及时接收医院结算信息，方便财务核对应收账款;</p>
            </div>
            <div class="ywxt-list">
              <img src="../../assets/images/medicine_platform_ywxt4.png" alt="" />
              <p>线上向医院一键推送合规资质的电子材料。</p>
            </div>
          </div>
        </div>
        <!-- 典型客户 -->
        <div class="main-contain">
          <Title titleZh="典型客户" colorZh="#151515" marginBottomZh="76px"></Title>
          <img class="w100f" src="../../assets/images/medicine_platform_dxkh.png" alt="" />
          <div class="h230"></div>
        </div>
      </div>
      <!-- 医链云-区域供应链采购平台-->
      <div class="yily-box" v-show="currentIndex == 1">
        <div class="main-contain">
          <Title titleZh="方案介绍" titleEn=""></Title>
          <img class="w90f" src="../../assets/images/medicine_platform_fajs.png" alt="" />
          <div class="h190"></div>
          <Tab :tabs="tabsYi" @tabChange="onTabYiChange" height="75px"> </Tab>
          <div class="h68"></div>
          <img class="w90f" v-show="yiTabIndex == 0" src="../../assets/images/medicine_platform_fajs_a1.png" alt="" />
          <img class="w90f" v-show="yiTabIndex == 1" src="../../assets/images/medicine_platform_fajs_a2.png" alt="" />
          <img class="w90f" v-show="yiTabIndex == 2" src="../../assets/images/medicine_platform_fajs_a3.png" alt="" />
          <div class="h130"></div>
        </div>
        <!-- 传世数字化服务 -->
        <div class="yily-grey-box">
          <div class="main-contain">
            <Title
              titleZh="传世数字化服务"
              titleEn="对接发货单位，抓取物流数据，证实交易真实性"
              fontSizeEn="16px"
              marginTopEn="32px"
              marginTopZh="0"
            ></Title>
            <img class="w100f" src="../../assets/images/medicine_platform_csszhfw.png" alt="" />
          </div>
        </div>
      </div>
      <!-- WMS-仓储管理系统 -->
      <div class="wms-box" v-show="currentIndex == 2">
        <div class="main-contain">
          <Title titleZh="系统架构" titleEn=""></Title>
          <img class="w100f" src="../../assets/images/medicine_platform_wms_xtjg.png" alt="" />
        </div>
        <div class="h140"></div>
        <div class="wms-grey-box">
          <Title titleZh="产品价值" titleEn="订单+数据驱动，通过各种策略加智能化设备应用，实现仓储精细化管理，支持多货主，多仓联动" fontSizeEn="16px" marginTopZh="0" marginTopEn="32px" marginBottomZh="94px"></Title>
          <div class="main-contain">
            <div class="grey-list-box">
              <img src="../../assets/images/medicine_platform_wms_cpjz1.png" alt="" />
              <div class="grey-list-title">适用于多种类型的物流中心</div>
              <p>
                适用平面仓库、立体化自动仓库、高架货架仓库、零整合一、零整分开、多货主、多仓管理等不同要求的物流中心。
              </p>
            </div>
            <div class="grey-list-box">
              <img src="../../assets/images/medicine_platform_wms_cpjz2.png" alt="" />
              <div class="grey-list-title">多业态的物流管理模式</div>
              <p>产品支持医药商批、医药工业、医药连锁、医药电商（B2B/B2C）等多种业态的物流管理模式。</p>
            </div>
            <div class="grey-list-box">
              <img src="../../assets/images/medicine_platform_wms_cpjz3.png" alt="" />
              <div class="grey-list-title">支持不同类型的物流设备</div>
              <p>
                有效支持电子标签、立库输送分拣、内复核分拣、集货分拣系统、LED显示、称重复核、滑块分拣机系统、笼车管理系统、语音拣选系统、RFID跟踪系统、PDA、平板电脑等多种设备；支持无设备情况下的纯手工纸单作业。
              </p>
            </div>
            <div class="grey-list-box">
              <img src="../../assets/images/medicine_platform_wms_cpjz4.png" alt="" />
              <div class="grey-list-title">灵活的流程及业务规则可配</div>
              <p>可根据各物流中心的特点，实现作业流程及系统参数可配置和自定义工作流程。</p>
            </div>
            <div class="grey-list-box">
              <img src="../../assets/images/medicine_platform_wms_cpjz5.png" alt="" />
              <div class="grey-list-title">精确灵活的货位管理</div>
              <p>根据物流中心的规划要求设置固定货位、机动货位，同时可根据现场的操作进行参数配置。</p>
            </div>
            <div class="grey-list-box">
              <img src="../../assets/images/medicine_platform_wms_cpjz6.png" alt="" />
              <div class="grey-list-title">完善的二次开发平台</div>
              <p>
                产品提供完善的二次开发平台，方便进行系统新需求以及查询、报表等功能开发，可以快速缩短开发时间，为客户后期的需求开发和维护提供了极大的便利。
              </p>
            </div>
          </div>
        </div>
        <div class="main-contain">
          <Title titleZh="支持多种作业类型" titleEn=""></Title>
          <div class="wms-zc-box">
            <div class="wms-zc-left">
              <p> · 支持RF手持作业</p>
              <p> · 支持PAD作业</p>
            </div>
            <img class="w50f" src="../../assets/images/medicine_platform_wms_zcdzzy.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 传世链条-开放式数据接口平台 -->
      <div class="cslt-box" v-show="currentIndex == 3">
        <div class="h60"></div>
        <div class="main-contain">
          <img class="w100f" src="../../assets/images/medicine_platform_cslt1.png" alt="">
          <Title titleZh="技术优势" titleEn=""></Title>
          <img class="w100f" src="../../assets/images/medicine_platform_cslt2.png" alt="">
        </div>
        <div class="h190"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import GreyBox from '@/components/box/greyBox.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
import mobilePage from '@/views/medicine/mobile/medicine_platform.vue'//移动端页面
export default {
  name: 'home',
  components: {
    GreyBox,
    Title,
    Tab,
    mobilePage
  },
  data() {
    return {
      currentIndex: 0,
      znsfTabIndex: 0,
      yiTabIndex: 0,
      znsfTab: [
        { title: '选仓算法' },
        { title: '选品算法' },
        { title: '销售预测算法' },
        { title: '智能排单排线排车算法' },
      ],
      tabs: [
        { mainTitle: '药链云-', title: 'SAAS药企供应链平台' },
        { mainTitle: '四方云-', title: '供应链&营销数字化平台' },
        { mainTitle: 'WMS-', title: '仓储管理系统' },
        { mainTitle: '传世链条-', title: '开放式数据接口平台' },
      ],
      tabsYi: [{ title: '任务派发' }, { title: '任务执行' }, { title: '到货签收' }],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    } 
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      console.log(index);
    },
    onTabYiChange(item, index) {
      this.yiTabIndex = index;
    },
    znsfTabChange(index) {
      this.znsfTabIndex = index;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
        }
        .font-txt {
          font-size: 24px;
          .font-txt-tag {
            margin: 0 20px;
          }
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //药链云
  .yly-box {
    .contain-intro-box {
      display: flex;
      img {
        width: 68%;
      }
      .contain-intro-txt {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        flex: 1;
        text-align: left;
        padding: 40px 0;
        background: #fafafa;
        .contain-intro-title {
          font-size: 20px;
          color: #3d3d3d;
          padding: 0 40px;
          margin-bottom: 25px;
        }
        p {
          font-size: 16px;
          color: #3d3d3d;
          text-indent: 2em;
          line-height: 25px;
          padding: 0 40px;
        }
      }
    }
    //三方物流
    .tpl-box {
      width: 100%;
      padding-top: 120px;
      padding-bottom: 85px;
      background: #fafafa;
      margin: 0 auto;
      .tpl-content-box {
        display: flex;
        justify-content: space-between;
        .tpl-list {
          position: relative;
          width: 32.5%;
          cursor: pointer;
          &:hover .tpl-txt-box {
            transition: 0.8s;
            height: 210px;
          }
          img {
            width: 100%;
          }
          .tpl-txt-box {
            position: absolute;
            width: 100%;
            height: 85px;
            left: 0;
            bottom: 0;
            color: #fff;
            text-align: left;
            padding: 20px;
            background: rgba(#000, 0.7);
            box-sizing: border-box;
            overflow: hidden;
            .tpl-title {
              font-size: 18px;
              margin-bottom: 15px;
            }
            p {
              font-size: 16px;
              line-height: 28px;
            }
          }
        }
      }
      //车网调度
      .cw-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .cw-list {
          //   width: 33.3%;
          height: 70px;
          display: flex;
          margin-bottom: 70px;
          box-sizing: border-box;
          img {
            width: 72px;
            height: 72px;
          }
          p {
            width: 166px;
            font-size: 16px;
            line-height: 26px;
            color: #737373;
            text-align: left;
            margin-left: 24px;
          }
        }
      }
    }
    //运输管理
    .tms-box {
      .tms-content-box {
        position: relative;
        width: 1350px;
        margin: auto;
        img {
          width: 100%;
        }
        .tms-bottom-box {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          display: flex;
          height: 90px;
          background: rgba(#000, 0.7);
          .tms-list {
            display: flex;
            align-items: center;
            width: 25%;
            color: #fff;
            font-size: 16px;
            line-height: 25px;
            text-align: left;
            padding: 0 40px 0 20px;
            box-sizing: border-box;
          }
        }
      }
    }
    //财务管理
    .cwgl-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .cwgl-list {
        width: 286px;
        height: 356px;
        text-align: left;
        border-radius: 2px;
        padding: 43px 30px 0 30px;
        box-sizing: border-box;
        border: 1px solid #edeeef;
        &:hover{
          transform:scale(1.05,1.05);
          box-shadow:0 0 9px 3px #ddd; 
          // cursor: pointer;
        }
        img {
          width: 50px;
          height: 50px;
          margin-bottom: 17px;
        }
        .cwgl-title {
          font-size: 22px;
          color: #151515;
        }
        .cwgl-line {
          width: 24px;
          height: 1px;
          margin: 53px 0;
          background: #707070;
        }
        p {
          font-size: 16px;
          line-height: 26px;
          color: #151515;
        }
      }
    }
    //业务协同
    .ywxt-box {
      display: flex;
      justify-content: space-between;
      .ywxt-list {
        width: 24%;
        text-align: left;
        &:hover{
          transform:scale(1.02,1.02);
          box-shadow:0 0 9px 3px #ddd; 
          p{
            padding-left: 5px;
            box-sizing: border-box;
          }
          // cursor: pointer;
        }
        img {
          width: 100%;
        }
        p {
          font-size: 16px;
          color: #737373;
          line-height: 26px;
          padding-right: 10px;
          margin: 10px 0;
          box-sizing: border-box;
        }
      }
    }
    //智能算法
    .znsf-box {
      padding-bottom: 80px;
      .znsf-wrap-box {
        width: 1300px;
        display: flex;
        margin: auto;
        .znsf-left {
          width: 624px;
          text-align: left;
          padding-right: 100px;
          box-sizing: border-box;
          //   border: 1px solid grey;
          .znsf-tab {
            display: flex;
            height: 62px;
            background: #f5f5f5;
            .znsf-tab-list {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
              padding: 0 30px;
              text-align: center;
              color: #151515;
              cursor: pointer;
              // &:hover{
              //   background: #fff;
              //   border-top: 6px solid #3ac2ab;
              // }
            }
            .znsf-tab-list-active {
              background: #fff;
              border-top: 6px solid #3ac2ab;
            }
          }
          .znsf-intro-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(100% - 62px);
            padding-right: 100px;
            box-sizing: border-box;
            .znsf-intro-title {
              font-size: 32px;
              color: #151515;
              margin-bottom: 24px;
            }
            p {
              color: #737373;
              font-size: 16px;
              line-height: 26px;
            }
            .znsf-btn {
              width: 144px;
              height: 48px;
              line-height: 48px;
              background: #1aa7a7;
              color: #fff;
              font-size: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 30px;
            }
          }
        }
        img {
          width: calc(100% - 624px);
          flex: 1;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
  }
  //四方云
  .yily-box {
    .yily-grey-box {
      padding: 75px 0 131px 0;
      background: #f5f8f8;
    }
  }
  //WMS
  .wms-box {
    .wms-grey-box {
      padding:39px 0 200px 0;
      background: #f5f8f8;
      .main-contain {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .grey-list-box {
          width: 480px;
          min-height: 200px;
          text-align: left;
          margin-bottom: 47px;
          img {
            width: 100%;
          }
          .grey-list-title {
            font-size: 24px;
            margin: 32px 0 24px 0;
          }
          p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .main-contain {
      width: 1100px;
    }
    .wms-zc-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 161px;
      .wms-zc-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        color: #3d3d3d;
        margin-right: 24px;
        p {
          font-size: 24px;
          margin-bottom: 42px;
        }
      }
    }
  }
}
</style>
