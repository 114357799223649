<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_medicine_platform_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange" ref="tabIndex"></mobileTab>
    <!-- 药链云-SAAS药企供应链平台 -->
    <div class="mobile-yly-block" v-show="currentIndex == 0">
      <!-- 方案介绍 -->
      <mobileTitle titleZh="方案介绍" marginTopZh=".64rem" marginBottomZh=".36rem" ></mobileTitle>
      <mobileImg
        :url="fajsImg"
        boxHeight="8.42rem"
        backColor="#fafafa"
        marginBox="0 .4rem"
        paddingBox=".6rem .24rem"
        border=".04rem solid #EBEBEB"
      ></mobileImg>
      <!-- 介绍 -->
      <mobileTitle
        titleZh="药链云-SAAS药企供应链平台"
        marginTopZh="1.12rem"
        marginBottomZh=".6rem"
        
      ></mobileTitle>
      <div class="mobile-yly-intro">
        <p>药链云平台支持医药企业的供应链全环节管理，是药企的供应链控制塔；</p>
        <p>
          传世药链云整合了订单管理(OMS)、三方物流(TPL)、配送管理(TMS)、多仓调度(MWD)核心模块，帮助药企低投入实现三方业务开展、全国仓网与车网的天网建设。
        </p>
        <p>传世药链云还集成了开放式接口平台，已与市场主流ERP、WMS系统接口。</p>
        <img src="../../../assets/mobile_images/mo_medicine_platform_yly_yly.png" alt="" />
      </div>
      <!-- 订单调度(OMS) -->
      <mobileTitle
        titleZh="订单调度(OMS)"
        titleEn="订单调度模块主要面向需要解决复杂订单需求的企业"
        marginTopZh="1.84rem"
        fontSizeEn=".28rem"
        marginTopEn=".28rem"
      ></mobileTitle>
      <div class="mobile-yly-oms">
        <div class="yly-oms-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_oms1.png" alt="" />
          <p>
            通过<span>即插即用的接口工具(CSI)</span>配合强大的开放式<span>接口平台(传世链条)</span>，帮助企业高效畅通、自动化无缝接收多来源订单；
          </p>
        </div>
        <div class="yly-oms-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_oms2.png" alt="" />
          <p>以灵活多变的订单规则定义引擎，实现<span>基于规则的计划性集单</span>(包括校验、合单、分单)；</p>
        </div>
        <div class="yly-oms-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_oms3.png" alt="" />
          <p>一键操作”订单调度”，将订单智能分配至仓网与车网，帮助企业建立<span>“供应链一体化订单体系”</span> 。</p>
        </div>
      </div>
      <!-- 三方物流(TPL) -->
      <div class="mobile-yly-tpl">
        <mobileTitle
          titleZh="三方物流(TPL)"
          titleEn="三方物流模块主要面向积极开展三方物流业务的企业"
          fontSizeEn=".28rem"
          marginTopEn=".28rem"
          marginTopZh=".52rem"
        ></mobileTitle>
        <div class="yly-tpl-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_tpl1.png" alt="" />
          <div class="yly-tpl-txt">
            <div class="yly-tpl-title">提供合规类数据报表</div>
            <p>质量类、三方业务类、库存类等，协助企业合规通过医药三方备案；</p>
          </div>
        </div>
        <div class="yly-tpl-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_tpl2.png" alt="" />
          <div class="yly-tpl-txt">
            <div class="yly-tpl-title">上游接通多类货主(工业、商业、B2B、B2C)的委托订单</div>
            <p>
              向货主提供全链路数据(订单执行数据、仓内作业数据、配送数据、冷链数据、含电子回单的回签数据等)、质量数据、库存数据、物流报价、费用核算等；
            </p>
          </div>
        </div>
        <div class="yly-tpl-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_tpl3.png" alt="" />
          <div class="yly-tpl-txt">
            <div class="yly-tpl-title">下游管理外租物流供应商(委外仓储、委外配送)</div>
            <p>管理内容包括合同、仓网与车网调度、服务质量监控、费用结算等；</p>
          </div>
        </div>
      </div>
      <!-- 仓网调度 -->
      <mobileTitle
        titleZh="仓网调度"
        titleEn="仓网调度模块主要面向有全国仓网布局需求的企业"
        marginTopZh="1.84rem"
        fontSizeEn=".28rem"
        marginTopEn=".28rem"
      ></mobileTitle>
      <div class="mobile-yly-cwdd">
        <div class="yly-cwdd-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_cwdd1.png" alt="" />
          <div class="yly-cwdd-title">仓网架构</div>
          <p>·MDC(工厂仓)- FDC(前置仓)；</p>
          <p>·CDC(总仓) -FDC(前置仓)-SPD (医院院内仓)；</p>
          <p>·CDC(总仓)-RDC(区配中心)-FDC(前置仓)-TDC(货站中转仓)；</p>
          <p>等多级多仓形态的仓网架构。</p>
        </div>
        <div class="yly-cwdd-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_cwdd2.png" alt="" />
          <div class="yly-cwdd-title">仓网形态</div>
          <p>支持自有仓+三方仓的混合型仓网形态</p>
        </div>
        <div class="yly-cwdd-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_cwdd3.png" alt="" />
          <div class="yly-cwdd-title">核心算法</div>
          <p>通过智能算法提供建仓选址、前置选品、合理备货、库存优化、调补货量等建议。</p>
        </div>
        <div class="yly-cwdd-list">
          <img src="../../../assets/mobile_images/mo_medicine_platform_yly_cwdd4.png" alt="" />
          <div class="yly-cwdd-title">接口方案</div>
          <p>提供标准接口方案，已实现与多家主流WMS供应商的系统对接。</p>
        </div>
      </div>
      <!-- 车网调度 -->
      <div class="mobile-yly-car">
        <mobileTitle
          titleZh="车网调度"
          titleEn="车网调度模块主要面向有建设全国运力网络需求的企业"
          fontSizeEn=".28rem"
          marginTopEn=".28rem"
          marginTopZh=".52rem"
          colorEn="#808080"
        ></mobileTitle>
        <img src="../../../assets/mobile_images/mo_medicine_platform_yly_car.png" alt="" />
      </div>
      <!-- 运输管理(TMS) -->
      <mobileTitle
        titleZh="运输管理(TMS)"
        titleEn="运输管理模块主要是面向需要有城配管理需求的企业"
        fontSizeEn=".28rem"
        marginTopEn=".28rem"
        marginTopZh="1.04rem"
        marginBottomZh=".74rem"
        colorEn="#808080"
      ></mobileTitle>
      <div class="mobile-yly-tms">
        <img src="../../../assets/mobile_images/mo_medicine_platform_yly_tms.png" alt="" />
        <div class="tms-intro">
          <p>支持自有车辆、紧密型合作承运商的多种形式车辆调度；</p>
          <p>提供智能排车排线算法，为企业节省车队管理成本；</p>
          <p>支持集单排车、一单一车/多车、分类排车(常温、冷链、精神品、危险品) 、灵活插单、并线并车、回货重排等；</p>
          <p>支持线路规划、手机签收、电子回单、司机考核等。</p>
        </div>
      </div>
      <!-- 财务管理 -->
      <mobileTitle
        titleZh="财务管理"
        titleEn="财务管理模块主要面向有复杂物流费用核算管理需求的企业"
        fontSizeEn=".28rem"
        marginTopEn=".3rem"
        marginTopZh="1.04rem"
        marginBottomZh=".48rem"
        colorEn="#808080"
        widthEn="5.88rem"
        lineEn=".5rem"
      ></mobileTitle>
      <mobileImg :url="cwglImg" boxHeight="4.34rem" marginBox="0 .4rem" paddingBox="0 0 .2rem 0"></mobileImg>
      <!-- 智能算法 -->
      <mobileTitle
        titleZh="智能算法"
        titleEn="算法驱动供应链实现智能调度"
        fontSizeEn=".28rem"
        marginTopEn=".3rem"
        marginTopZh="1.04rem"
        marginBottomZh=".48rem"
        colorEn="#808080"
        widthEn="5.88rem"
        lineEn=".5rem"
      ></mobileTitle>
      <div class="mobile-yly-znsf">
        <div class="znsf-list">
          <div class="img-box">
            <img src="../../../assets/mobile_images/mo_medicine_platform_yly_znsf1.png" alt="" />
          </div>
          <div class="znsf-txt">
            <div class="znsf-title">选仓算法</div>
            <p>
              根据客户的需求数据(即销量数据)以及工厂到各个客户的时间要求、成本要求以及紧急程度等，以时间成本优先原则，利用重心法、P中值等选仓模型选择最佳的仓库位置，实现多方共赢。
            </p>
          </div>
        </div>
        <div class="znsf-list">
          <div class="img-box">
            <img src="../../../assets/mobile_images/mo_medicine_platform_yly_znsf2.png" alt="" />
          </div>
          <div class="znsf-txt">
            <div class="znsf-title">选品算法</div>
            <p>
              利用仓库发货数据，根据货品的需求频率和需求稳定度两个维度来进行选品分析，选择需求频率高、需求稳定度高的短尾产品实现前置仓的周转率，减少需求频率极低、需求稳定度不高的长尾产品，降低库存成本。
            </p>
          </div>
        </div>
        <div class="znsf-list">
          <div class="img-box">
            <img src="../../../assets/mobile_images/mo_medicine_platform_yly_znsf3.png" alt="" />
          </div>
          <div class="znsf-txt">
            <div class="znsf-title">销售预测算法</div>
            <p>
              根据以往的销售数据，通过观察数据的趋势、周期、季节性等因素，利用ARIMA模型预测未来周期的销售数据，以便于仓库的补货。
            </p>
          </div>
        </div>
        <div class="znsf-list">
          <div class="img-box">
            <img src="../../../assets/mobile_images/mo_medicine_platform_yly_znsf4.png" alt="" />
          </div>
          <div class="znsf-txt">
            <div class="znsf-title">智能排单排线排车算法</div>
            <p>
              多达40项业务参数形成基于条件的配送路径优化(VCRP)，智能生成配送计划；算法涵盖时间窗口、路网矩阵、满载率、车型匹配、多点配送等因素；实现成本最优、里程最优、耗时最短、均衡规划。
            </p>
          </div>
        </div>
      </div>
      <!-- 数据分析 -->
      <div class="mobile-yly-car">
        <mobileTitle
          titleZh="数据分析"
          titleEn="强大的自研低代码BI模块，为客户提供极其方便的数据统计、分析、挖掘、导出能力，输出效果极其优美"
          fontSizeEn=".28rem"
          marginTopEn=".28rem"
          marginTopZh=".52rem"
          marginBottomZh=".62rem"
          widthEn="5.72rem"
          colorEn="#808080"
        ></mobileTitle>
        <img src="../../../assets/mobile_images/mo_medicine_platform_yly_sjfx.png" alt="" />
      </div>
      <!-- 业务协同 -->
      <div class="mobile-yly-ywxt">
        <mobileTitle
          titleZh="业务协同"
          titleEn="业务协同模块主要面向有线上协同医院采购供应业务的企业"
          fontSizeEn=".28rem"
          marginTopEn=".28rem"
          marginTopZh=".52rem"
          marginBottomZh=".62rem"
          widthEn="5.72rem"
          colorEn="#808080"
        ></mobileTitle>
        <img src="../../../assets/mobile_images/mo_medicine_platform_yly_ywxt.png" alt="" />
        <p>连通医院订单至企业ERP，快速响应医院的采购要求;</p>
      </div>
    </div>
    <!-- 四方云-供应链&营销数字化平台 -->
    <div class="mobile-sfy-block" v-show="currentIndex == 1">
      <!-- 产品介绍 -->
      <mobileTitle titleZh="产品介绍" marginTopZh=".64rem" marginBottomZh=".36rem" ></mobileTitle>
      <mobileImg
        :url="sfyIntroImg"
        boxHeight="8.42rem"
        backColor="#fafafa"
        marginBox="0 .16rem"
        paddingBox=".6rem .24rem"
        border=".04rem solid #EBEBEB"
      ></mobileImg>
      <!-- 任务派发 -->
      <mobileTitle titleZh="任务派发" marginTopZh=".64rem" marginBottomZh=".36rem" ></mobileTitle>
      <mobileImg
        :url="sfyTaskImg1"
        boxHeight="11.44rem"
        backColor="#fafafa"
        marginBox="0 .16rem"
        paddingBox=".3rem .24rem"
        border=".04rem solid #EBEBEB"
      ></mobileImg>
      <!-- 任务执行 -->
      <mobileTitle titleZh="任务执行" marginTopZh=".64rem" marginBottomZh=".36rem" ></mobileTitle>
      <mobileImg
        :url="sfyTaskImg2"
        boxHeight="8.42rem"
        backColor="#fafafa"
        marginBox="0 .16rem"
        paddingBox=".3rem .24rem"
        border=".04rem solid #EBEBEB"
      ></mobileImg>
      <!-- 到货签收 -->
      <mobileTitle titleZh="到货签收" marginTopZh=".64rem" marginBottomZh=".36rem" ></mobileTitle>
      <mobileImg
        :url="sfyTaskImg3"
        boxHeight="8.42rem"
        backColor="#fafafa"
        marginBox="0 .16rem"
        paddingBox=".3rem .24rem"
        border=".04rem solid #EBEBEB"
      ></mobileImg>
      <!-- 传世数字化服务 -->
      <mobileTitle
        titleZh="传世数字化服务"
        titleEn="对接发货单位，抓取物流数据，证实交易真实性"
        marginTopZh="1.84rem"
        fontSizeEn=".28rem"
        marginTopEn=".28rem"
        marginBottomZh=".84rem"
      ></mobileTitle>
      <img class="w75f" src="../../../assets/mobile_images/mo_medicine_platform_sfy_cssz.png" alt="" />
    </div>
    <!-- WMS-仓储管理系统 -->
    <div class="mobile-wms-block" v-show="currentIndex == 2">
      <!-- 产品介绍 -->
      <mobileTitle titleZh="产品介绍" marginTopZh=".64rem" marginBottomZh=".36rem" ></mobileTitle>
      <mobileImg
        :url="wmsIntroImg"
        boxHeight="9.6rem"
        backColor="#fafafa"
        marginBox="0 .4rem"
        paddingBox=".3rem"
        border=".04rem solid #EBEBEB"
      ></mobileImg>
      <!-- 产品价值 -->
      <mobileTitle
        titleZh="产品价值"
        titleEn="订单+数据驱动，通过各种策略加智能化设备应用，实现仓储精细化管理，支持多货主，多仓联动"
        marginTopZh="1.84rem"
        fontSizeEn=".28rem"
        marginTopEn=".28rem"
        marginBottomZh=".84rem"
        colorEn="#808080"
        widthEn="6.7rem"
        lineEn=".5rem"
      ></mobileTitle>
      <div class="mobile-wms-cpjz">
        <div class="wms-cpjz-list" v-for="(item,index) in wmsList" :key="index">
          <img :src="item.url" alt="" />
          <div class="cpjz-title">{{item.title}}</div>
          <p>{{item.txt}}</p>
        </div>
      </div>
      <!-- 支持多种作业类型 -->
      <mobileTitle titleZh="支持多种作业类型" marginTopZh=".78rem" marginBottomZh=".36rem" ></mobileTitle>
      <div class="mobile-wms-zc">
        <img src="../../../assets/mobile_images/mo_medicine_platform_wms_zczy.png" alt="">
        <p> · 支持RF手持作业 <span></span>  · 支持PAD作业</p>
      </div>
    </div>
    <!-- 传世链条-开放式数据接口平台 -->
    <div class="mobile-cslt-block" v-show="currentIndex == 3">
      <img class="w100f" src="../../../assets/mobile_images/mo_medicine_platform_cslt1.png" alt="">
      <mobileTitle titleZh="技术优势" marginTopZh=".72rem" marginBottomZh=".8rem" ></mobileTitle>
      <img class="w85f" src="../../../assets/mobile_images/mo_medicine_platform_cslt2.png" alt="">
    </div>
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      //药链云图片
      fajsImg: require('../../../assets/mobile_images/mo_medicine_platform_yly_fajs.png'),
      cwglImg: require('../../../assets/mobile_images/mo_medicine_platform_yly_cwgl.png'),
      //四方云图片
      sfyIntroImg: require('../../../assets/mobile_images/mo_medicine_platform_sfy_cpjs.png'),
      sfyTaskImg1: require('../../../assets/mobile_images/mo_medicine_platform_sfy_rwpf.png'),
      sfyTaskImg2: require('../../../assets/mobile_images/mo_medicine_platform_sfy_rwzx.png'),
      sfyTaskImg3: require('../../../assets/mobile_images/mo_medicine_platform_sfy_dhqs.png'),
      //WMS图片
      wmsIntroImg: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjs.png'),
      currentIndex: 0,
      tabs: [
        { mainTitle: '药链云-', title: 'SAAS药企供应链平台' },
        { mainTitle: '四方云-', title: '供应链&营销数字化平台' },
        { mainTitle: 'WMS-', title: '仓储管理系统' },
        { mainTitle: '传世链条-', title: '开放式数据接口平台' },
      ],
      wmsList: [
        {
          url: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjz1.png'),
          title: '适用于多种类型的物流中心',
          txt: '适用平面仓库、立体化自动仓库、高架货架仓库、零整合一、零整分开、多货主、多仓管理等不同要求的物流中心。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjz2.png'),
          title: '多业态的物流管理模式',
          txt: '产品支持医药商批、医药工业、医药连锁、医药电商（B2B/B2C）等多种业态的物流管理模式。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjz3.png'),
          title: '支持不同类型的物流设备',
          txt: '有效支持电子标签、立库输送分拣、内复核分拣、集货分拣系统、LED显示、称重复核、滑块分拣机系统、笼车管理系统、语音拣选系统、RFID跟踪系统、PDA、平板电脑等多种设备；支持无设备情况下的纯手工纸单作业。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjz4.png'),
          title: '灵活的流程及业务规则可配',
          txt: '可根据各物流中心的特点，实现作业流程及系统参数可配置和自定义工作流程。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjz5.png'),
          title: '精确灵活的货位管理',
          txt: '根据物流中心的规划要求设置固定货位、机动货位，同时可根据现场的操作进行参数配置。',
        },
        {
          url: require('../../../assets/mobile_images/mo_medicine_platform_wms_cpjz6.png'),
          title: '完善的二次开发平台',
          txt: '产品提供完善的二次开发平台，方便进行系统新需求以及查询、报表等功能开发，可以快速缩短开发时间，为客户后期的需求开发和维护提供了极大的便利。',
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.index) {
      let index = Number(this.$route.query.index);
      this.currentIndex = index;
      this.$refs.tabIndex.currentIndex = index;
    }
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  //药链云介绍
  .mobile-yly-intro {
    padding: 0 0.65rem;
    box-sizing: border-box;
    p {
      color: #3d3d3d;
      font-size: 0.32rem;
      line-height: 0.56rem;
      text-align: left;
      text-indent: 2em;
    }
    img {
      width: 98%;
      margin-top: 0.6rem;
    }
  }
  //药链云-OMS
  .mobile-yly-oms {
    padding: 0 0.95rem 1.36rem 0.95rem;
    box-sizing: border-box;
    .yly-oms-list {
      font-size: 0.32rem;
      margin-top: 0.78rem;
      img {
        width: 100%;
        margin-top: 0.26rem;
      }
      p {
        color: #3d3d3d;
        line-height: 0.6rem;
        text-align: left;
      }
      span {
        color: #3ac2ab;
      }
    }
  }
  //药链云-TPL
  .mobile-yly-tpl {
    padding: 1px 0.4rem 0 0.4rem;
    background: #f7f8f8;
    .yly-tpl-list {
      position: relative;
      margin-top: 0.84rem;
      img {
        width: 100%;
      }
      .yly-tpl-txt {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0.58rem 0.45rem;
        color: #fff;
        background: rgba(0, 0, 0, 0.7);
        text-align: left;
        box-sizing: border-box;
        .yly-tpl-title {
          font-size: 0.36rem;
          line-height: 0.44rem;
          margin-bottom: 0.52rem;
        }
        p {
          font-size: 0.32rem;
          line-height: 0.5rem;
          letter-spacing: 0.03rem;
        }
      }
    }
  }
  //药链云-仓网调度
  .mobile-yly-cwdd {
    padding: 0 0.4rem 1.36rem 0.4rem;
    box-sizing: border-box;
    .yly-cwdd-list {
      font-size: 0.32rem;
      margin-top: 0.78rem;
      text-align: left;
      box-sizing: border-box;
      img {
        width: 64%;
        margin-top: 0.26rem;
        margin-bottom: 0.58rem;
        padding-left: 18%;
      }
      .yly-cwdd-title {
        color: #151515;
        font-size: 0.36rem;
        margin-bottom: 0.34rem;
      }
      p {
        font-size: 0.28rem;
        color: #737373;
        line-height: 0.52rem;
      }
    }
  }
  //药链云-车网调度
  .mobile-yly-car {
    padding: 1px 0 0.64rem 0;
    background: #f7f8f8;
    img {
      width: 89%;
    }
  }
  //药链云-TMS
  .mobile-yly-tms {
    img {
      width: 100%;
    }
    .tms-intro {
      font-size: 0.32rem;
      padding: 0.4rem 0.65rem;
      color: #fff;
      text-align: left;
      background: rgba(0, 0, 0, 0.7);
      box-sizing: border-box;
      p {
        line-height: 0.52rem;
        margin-bottom: 0.4rem;
      }
    }
  }
  //药链云-智能算法
  .mobile-yly-znsf {
    .znsf-list {
      width: 100%;
      padding-bottom: 1.66rem;
      margin: auto;
      .img-box {
        padding: 0 0.4rem;
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }
      &:nth-child(1) {
        .img-box {
          padding: 0 0.8rem;
        }
      }
      &:nth-child(4) {
        .img-box {
          padding: 0;
        }
      }
      .znsf-txt {
        width: 100%;
        padding: 0 0.4rem;
        text-align: left;
        margin-top: 0.88rem;
        box-sizing: border-box;
        .znsf-title {
          color: #151515;
          font-size: 0.4rem;
          margin-bottom: 0.32rem;
        }
        p {
          color: #737373;
          font-size: 0.32rem;
          line-height: 0.52rem;
        }
      }
    }
  }
  //药链云-业务协同
  .mobile-yly-ywxt {
    padding: 0 0.4rem;
    box-sizing: border-box;
    img {
      width: 100%;
    }
    p {
      font-size: 0.32rem;
      color: #737373;
      line-height: 0.52rem;
      margin-top: 0.44rem;
      text-align: left;
    }
  }
  //WMS-产品价值
  .mobile-wms-cpjz{
    padding: 0 .4rem;
    box-sizing: border-box;
    .wms-cpjz-list{
      padding-top: .44rem;
      text-align: left;
      img{
        width: 100%;
      }
      .cpjz-title{
        font-size: .36rem;
        margin: .4rem 0 .36rem 0;
      }
      p{
        color: #808080;
        line-height: .5rem;
      }
    }
  }
  //WMS-支持多种
  .mobile-wms-zc{
    text-align: center;
    img{
      width: 5.5rem;
      margin: .44rem 0;
    }
    p{
      font-size: .28rem;
      color: #3D3D3D;
      span{
        display: inline-block;
        width: .5rem;
      }
    }
  }
}
</style>
