<template>
  <div class="grey-content">
    <div class="grey-content-list" v-for="(item, index) in boxList" :key="index">
      <img :src="item.url" alt="" />
      <div class="grey-list-box">
        <div class="grey-list-title" v-show="item.title">{{ item.title }}</div>
        <div class="grey-list-content" v-html="item.txt"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    boxList: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style lang="less" scoped>
.grey-content {
  width: 1200px;
  text-align: center;
  margin: auto;
  .grey-content-list {
    display: flex;
    margin-bottom: 130px;
    &:nth-child(2n) {
      flex-direction: row-reverse;
      .grey-list-box {
        padding-left: 0;
      }
    }
    img {
      width: 50%;
    }
    .grey-list-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 48px;
      box-sizing: border-box;
      .grey-list-title {
        font-size: 24px;
        color: #3d3d3d;
        text-align: left;
        margin-bottom: 20px;
      }
      .grey-list-content {
        text-align: left;
        /deep/p {
          font-size: 16px;
          line-height: 30px;
          text-indent: 2em;
        }
      }
    }
  }
}
</style>
